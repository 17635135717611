import { APP_INITIALIZER, inject } from '@angular/core';
import { PurchaseOrderDto, PurchaseOrderEntityName, PurchaseOrderLineDto, PurchaseOrderLineEntityName } from '@nest/domain/entities-metadata/dtos';
import { PurchaseOrderDataAccessService, PurchaseOrderLineDataAccessService } from '@nest/domain/erp/purchase-orders/frontend/data-access';
import { EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { PurchaseOrderStateColorEntityPathRendererComponent } from './purchase-order-state-color-path-render.component';

const paths = autoPathsFor<PurchaseOrderDto>();

export const PurchaseOrderDefaultElements = fieldsToElements(
	paths(['createdAt', 'documentNumber', 'contractor.name', 'state', 'issuedDate', 'foreignOrderNumber', 'supplierPricelist.name', 'note', 'lines'])
);
export const PurchaseOrderLineDefaultElements = fieldsToElements(
	autoPathsFor<PurchaseOrderLineDto>()(['purchaseOrder.documentNumber', 'code', 'text', 'quantity', 'price.netto', 'totalPrice.netto', 'supplierPricelist.name'])
);

export function providePurchaseOrdersEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				let purchaseOrderLineDataAccess = inject(PurchaseOrderLineDataAccessService);
				let purchaseOrderDataAccess = inject(PurchaseOrderDataAccessService);

				return () => {
					entityMetadata.registerEntity({
						entityName: PurchaseOrderLineEntityName,
						dataAccess: purchaseOrderLineDataAccess,
						defaultElements: PurchaseOrderLineDefaultElements
					});

					entityMetadata.registerEntity({
						entityName: PurchaseOrderEntityName,
						dataAccess: purchaseOrderDataAccess,
						defaultElements: PurchaseOrderDefaultElements
					});

					entityMetadata.registerEntityPathRenderer(PurchaseOrderEntityName, paths(['state']), PurchaseOrderStateColorEntityPathRendererComponent);
				};
			}
		}
	];
}
