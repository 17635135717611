/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { PurchaseOrderDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class PurchaseOrderDataAccessService {
	private api = inject(ApiService);

	// GET @ suppliers/orders/
	getAll(query: EntityQueryDto<PurchaseOrderDto>) {
		return this.api.post<PurchaseOrderDto[]>(`purchaseOrders.getAll`, { body: { data: query } });
	}

	// GET @ suppliers/orders/:id
	getById(id: number, query: FindOptionsDto<PurchaseOrderDto>) {
		return this.api.post<PurchaseOrderDto>(`purchaseOrders.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<PurchaseOrderDto>) {
		return this.api.post<number>(`purchaseOrders.count`, { body: { data: query } });
	}

	// POST @ suppliers/orders/:id?
	save(id: number, body: PurchaseOrderDto) {
		return this.api.post<PurchaseOrderDto>(`suppliers/orders/${id}?`, { body });
	}

	// POST @ suppliers/orders/:id/cancel
	cancel(id: number) {
		return this.api.post<void>(`suppliers/orders/${id}/cancel`, {});
	}
}
